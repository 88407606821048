.mainPage__title {
  font-family: 'Roboto Slab', serif;
  font-size: var(--font-size-4xl);
  font-weight: 300;
  line-height: var(--line-height-3xl);

  max-width: 268px;
  margin: var(--space-2xl) auto var(--space-xl);

  text-align: center;

  @media (--tablet) {
    font-size: var(--font-size-4xl);
    line-height: var(--line-height-3xl);

    max-width: 350px;
    margin-top: var(--space-3xl);
  }

  @media (--desktop-s) {
    font-size: var(--font-size-7xl);
    line-height: var(--line-height-5xl);

    max-width: 700px;
    margin-top: var(--space-4xl);
  }

  @media (--desktop-m) {
    margin-bottom: var(--space-2xl);
  }
}

.mainPage__subtitle {
  font-size: var(--font-size-l);
  font-weight: 100;
  line-height: var(--line-height-xl);

  margin-right: auto;
  margin-bottom: var(--space-xl);
  margin-left: auto;

  text-align: center;

  @media (--tablet) {
    font-size: var(--font-size-xl);

    max-width: 596px;
    margin-bottom: var(--space-3xl);
  }

  @media (--desktop-s) {
    font-size: var(--font-size-4xl);
    line-height: var(--line-height-3xl);

    max-width: 780px;
  }

  @media (--desktop-m) {
    max-width: 800px;
  }
}

.mainPage__subtitle b {
  font-weight: 400;
}
