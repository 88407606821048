.example-project {
  margin-top: var(--space-4xl);
  margin-bottom: var(--space-3xl);

  @media (--tablet) {
    margin-top: var(--space-3xl);
    margin-bottom: var(--space-4xl);
  }

  @media (--desktop-s) {
    margin-top: var(--space-4xl);
  }

  @media (--desktop-m) {
    margin-top: 90px;
    margin-bottom: 96px;
  }
}

.example-project__container {
  display: grid;

  @media (--tablet) {
    grid-template-columns: repeat(12, 38px);
    column-gap: var(--space-xl);
  }

  @media (--desktop-s) {
    grid-template-columns: repeat(12, 53px);
  }

  @media (--desktop-m) {
    grid-template-columns: repeat(12, 78px);
  }
}

.diagrams__wrap {
  position: relative;

  @media (--tablet) {
    margin-top: 38px;
    margin-right: 49px;

    border-right: 1px solid var(--color-black-olive);
    border-left: 1px solid var(--color-black-olive);
    grid-column: 1 / span 9;
  }

  @media (--desktop-s) {
    margin-top: 0;
    margin-right: 25px;
    grid-column: 1 / span 8;
  }

  @media (--desktop-m) {
    margin-right: 67px;
  }
}

.diagrams {
  position: relative;

  display: grid;

  padding: var(--space-m) 0;

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, min-content);
  column-gap: 1px;
  row-gap: var(--space-2xl);

  @media (--tablet) {
    padding: var(--space-xl) 0;
  }

  @media (--desktop-s) {
    padding: var(--space-2xl) 0;
    row-gap: 39px;
  }

  @media (--desktop-m) {
    row-gap: 30px;
  }
}

.diagrams::before,
.diagrams::after {
  position: absolute;
  z-index: -1;
  top: 0;

  width: 62px;
  height: 100%;

  content: '';

  @media (--tablet) {
    width: 122px;
  }

  @media (--desktop-s) {
    width: 143px;
  }

  @media (--desktop-m) {
    width: 182px;
  }
}

.diagrams::before {
  left: 60px;

  border-right: 1px solid var(--color-black-olive);
  border-left: 1px solid var(--color-black-olive);

  @media (--tablet) {
    left: 120px;
  }

  @media (--desktop-s) {
    left: 140px;
  }

  @media (--desktop-m) {
    left: 180px;
  }
}

.diagrams::after {
  left: 121px;

  border-right: 1px solid var(--color-black-olive);

  @media (--tablet) {
    left: 241px;
  }

  @media (--desktop-s) {
    left: 281px;
  }

  @media (--desktop-m) {
    left: 361px;
  }
}

.diagrams__img {
  position: absolute;
  top: 0;
  right: 0;

  width: 71px;
  height: 74px;

  transform: translateY(-54px) translateX(-10px);

  @media (--tablet) {
    width: 84px;
    height: 84px;

    transform: translateY(-74px) translateX(94px);
  }

  @media (--desktop-s) {
    width: 142px;
    height: 147px;

    transform: translateY(-61px) translateX(147px);
  }

  @media (--desktop-m) {
    transform: translateY(-61px) translateX(185px);
  }
}

.diagram {
  position: relative;
}

.diagram__title {
  font-size: var(--font-size-l);
  font-weight: 900;

  @media (--desktop-m) {
    font-size: var(--font-size-3xl);
    line-height: var(--line-height-xl);
  }
}

.diagram__value {
  font-weight: 300;
}

.diagram::after {
  display: block;

  width: 100%;
  height: 16px;
  margin-top: var(--space-3xs);

  content: '';

  border-radius: 8px;
}

.diagram_digital {
  grid-column: 1 / span 4;
  grid-row: 1;
}

.diagram_digital::after {
  width: 36.6%;

  background: linear-gradient(62.8deg, #e62cf6 24.16%, #ffd576 69.7%);

  @media (--tablet) {
    width: 49.3%;
  }

  @media (--desktop-s) {
    width: 50%;
  }

  @media (--desktop-m) {
    width: 49.7%;
  }
}

.diagram_cloud {
  grid-column: 1 / span 4;

  padding-left: 30px;
  grid-row: 2;

  @media (--tablet) {
    padding-left: 60px;
  }

  @media (--desktop-s) {
    padding-left: 72px;
  }

  @media (--desktop-m) {
    padding-left: 90px;
  }
}

.diagram_cloud::after {
  width: 40.5%;

  background: linear-gradient(110.94deg, #87dcb7 37.25%, #2c78f6 73.39%);

  @media (--tablet) {
    width: 57%;
  }

  @media (--desktop-s) {
    width: 57%;
  }

  @media (--desktop-m) {
    width: 57%;
  }
}

.diagram_web {
  grid-column: 2 / span 3;
  grid-row: 3;

  transform: translateX(-22px);

  @media (--tablet) {
    transform: translateX(0);
  }

  @media (--desktop-s) {
    transform: translateX(0);
  }
}

.diagram_web::after {
  width: 73%;
  height: 48px;

  background: linear-gradient(62.8deg, #7c15ff 24.16%, #769cff 102.94%);

  @media (--tablet) {
    width: 100%;
  }
}

.diagram_qa {
  grid-column: 2 / span 3;
  grid-row: 4;

  padding-left: 8px;

  @media (--tablet) {
    padding-left: 59px;
  }

  @media (--desktop-s) {
    padding-left: 70px;
  }

  @media (--desktop-m) {
    padding-left: 90px;
  }
}

.diagram_qa::after {
  width: 63%;

  background: linear-gradient(62.8deg, #ff7979 24.16%, #89ff76 102.94%);

  @media (--tablet) {
    width: 100%;
  }
}

.project-costs {
  max-width: 211px;
  margin-top: var(--space-2xl);

  @media (--tablet) {
    grid-column: 10 / span 3;
  }

  @media (--desktop-s) {
    max-width: 100%;
    margin-top: var(--space-3xl);
  }

  @media (--desktop-m) {
    margin-top: var(--space-4xl);
  }
}

.project-costs__title {
  font-family: 'Roboto Slab', sans-serif;
  font-size: var(--font-size-3xl);
  font-weight: 400;
  line-height: var(--line-height-xl);

  @media (--desktop-s) {
    padding-right: var(--space-2xl);
  }
}

.costs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--space-m);

  margin-top: var(--space-xl);
  margin-left: var(--space-m);

  @media (--tablet) {
    grid-template-columns: 1fr;

    margin-left: 0;
  }
}

.cost {
  display: flex;
  flex-direction: column;
}

.cost__title {
  font-size: inherit;
  font-weight: 900;
}

.cost__value {
  font-weight: 300;

  margin-top: var(--space-3xs);
}

.costs-total {
  margin-top: var(--space-xl);
}

.costs-total__title {
  font-size: var(--font-size-3xl);
  font-weight: 900;
  line-height: var(--line-height-xl);
}

.costs-total__value {
  font-weight: 300;
}
