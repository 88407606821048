.page-header {
  font-family: 'Roboto Slab', serif;

  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);

  @media (--tablet) {
    padding-right: var(--space-l);
    padding-left: var(--space-l);
  }

  @media (--desktop-s) {
    padding-right: var(--space-2xl);
    padding-left: var(--space-2xl);
  }
}

.page-header__logo {
  margin-right: var(--space-s);

  @media (--tablet) {
    margin-right: var(--space-m);
  }
}

.page-header__top-row {
  display: flex;
  align-items: center;

  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);

  @media (--tablet) {
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
  }
}

.page-header__company-name {
  font-size: var(--font-size-s);
  font-weight: 700;

  @media (--tablet) {
    font-size: var(--font-size-l);
  }
}

.page-header__label {
  font-family: Roboto, sans-serif;
  font-size: var(--font-size-2xs);
  font-weight: 900;
  line-height: var(--line-height-2xs);

  margin-left: auto;
  padding: var(--space-2xs);

  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: var(--color-black);
  border-radius: 8px;
  background-color: var(--color-yellow);

  @media (--tablet) {
    font-size: var(--font-size-s);
    line-height: var(--line-height-xs);

    padding: var(--space-xs);
  }
}
