.applicationForm {
  display: flex;
  flex-direction: column;

  min-height: 615px;
  margin-right: auto;
  margin-bottom: var(--space-3xl);
  margin-left: auto;
  padding: var(--space-m);

  background-color: var(--bg-color-night);

  @media (--tablet) {
    flex-direction: row;

    max-width: 720px;
    min-height: 379px;
    margin-bottom: var(--space-4xl);
    padding: var(--space-2xl);
  }

  @media (--desktop-s) {
    max-width: 748px;
    min-height: 411px;
    padding: var(--space-3xl);
  }

  @media (--desktop-m) {
    max-width: 792px;
    margin-bottom: var(--space-5xl);
  }
}

.applicationForm__contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: var(--space-m);

  @media (--tablet) {
    margin-right: var(--space-xl);
    margin-bottom: 0;
  }
}

.applicationForm__headerText {
  display: flex;
  align-items: center;

  margin-bottom: var(--space-m);

  &::after {
    width: 16px;
    height: 16px;
    margin-left: var(--space-3xs);

    content: '';

    background-image: url('../assets/hand.png');
    background-position: 50% 50%;
    background-size: contain;
  }

  @media (--tablet) {
    font-size: var(--font-size-3xl);
    line-height: var(--line-height-2xl);

    &::after {
      width: 24px;
      height: 24px;
    }
  }
}

.applicationForm__avatar {
  margin-bottom: var(--space-m);

  @media (--tablet) {
    width: 132px;
    height: 96px;
    margin-bottom: var(--space-xl);
  }
}

.applicationForm__employeeName {
  font-weight: 700;

  margin-bottom: var(--space-xs);

  @media (--tablet) {
    margin-bottom: 2px;
  }
}

.applicationForm__employeeEmail {
  font-size: var(--font-size-s);
  line-height: var(--line-height-xs);
}

.applicationForm__steps {
  width: 100%;
  padding-top: var(--space-m);

  border-top: 1px solid var(--color-gray25);

  @media (--tablet) {
    padding-top: 0;
    padding-left: var(--space-xl);

    border-top: none;
    border-left: 1px solid var(--color-gray25);
  }
}

.applicationForm__form {
  display: grid;
  flex-grow: 1;

  row-gap: var(--space-m);

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, min-content);
    column-gap: var(--space-m);
  }
}

.applicationForm__field_message,
.applicationForm__submitButton-wrap {
  @media (--tablet) {
    grid-column: 1 / span 2;
  }
}

.applicationForm__submitButton {
  width: 100%;
}

.applicationForm__submitButton-wrap {
  position: relative;
}

.applicationForm__email,
.applicationForm__messengers {
  width: 100%;
}

.applicationForm__message {
  width: 100%;
  height: 150px;

  vertical-align: top;

  @media (--tablet) {
    height: 170px;
  }
}

.applicationForm__error {
  font-size: var(--space-s);
  line-height: var(--font-size-m);

  margin-top: var(--space-3xs);

  color: var(--color-error);
}

.applicationForm__successScreen {
  display: none;
}

.applicationForm__checkIcon {
  display: block;

  width: 39px;
  height: 30px;
  margin-top: var(--space-xs);
  margin-bottom: var(--space-m);
}

.applicationForm--state-success .applicationForm__form {
  display: none;
}

.applicationForm--state-success .applicationForm__successScreen {
  display: block;
}
