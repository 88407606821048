/* Box Sizing */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: Roboto, Arial, sans-serif;
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);

  min-width: 320px;
  margin: 0;

  color: var(--color-primary);
  background-color: var(--bg-color-dark);
}

h1,
h2,
h3,
p {
  margin: 0;
}

ul,
p {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}

.container {
  display: block;

  width: 100%;
  max-width: 328px;
  margin: 0 auto;
}

@media (--tablet) {
  .container {
    max-width: 720px;
  }
}

@media (--desktop-s) {
  .container {
    max-width: 904px;
  }
}

@media (--desktop-m) {
  .container {
    max-width: 1200px;
  }
}

.visually-hidden {
  position: absolute;

  overflow: hidden;
  clip: rect(0 0 0 0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  border: 0;
  clip-path: inset(100%);
}
