.employeeCards {
  display: grid;
  align-items: center;

  margin-bottom: var(--space-xl);
  row-gap: var(--space-xl);

  @media (--tablet) {
    align-items: stretch;
    grid-template-columns: repeat(2, 1fr);

    margin-bottom: var(--space-4xl);
    column-gap: var(--space-xl);
  }

  @media (--desktop-m) {
    margin-bottom: var(--space-5xl);
  }
}

.employeeCard {
  display: flex;
  flex-direction: column;

  width: 328px;
  padding: var(--space-m);

  background-color: var(--bg-color-night);

  @media (--tablet) {
    width: 348px;
    padding: var(--space-xl);
  }

  @media (--desktop-s) {
    width: 438px;
    padding: var(--space-2xl);
  }

  @media (--desktop-m) {
    width: 588px;
    padding: var(--space-4xl);
  }
}

.employeeCard__title {
  font-family: 'Roboto Slab', serif;
  font-size: var(--font-size-3xl);
  font-weight: 400;
  line-height: var(--line-height-xl);

  margin-bottom: var(--space-m);

  @media (--desktop-m) {
    font-size: var(--font-size-6xl);
    line-height: var(--line-height-4xl);
  }
}

.employeeCard__subtitle {
  margin-top: 0;
  margin-bottom: var(--space-m);

  @media (--tablet) {
    min-height: 72px;
  }

  @media (--desktop-m) {
    font-size: var(--font-size-3xl);
    line-height: var(--line-height-2xl);

    margin-bottom: var(--space-xl);
  }
}

.employeeCard__avatar {
  width: 80px;
  height: 80px;

  @media (--tablet) {
    width: 96px;
    height: 96px;
  }
}

.employeeCard__picture {
  margin-top: auto;
  margin-bottom: var(--space-s);

  @media (--tablet) {
    margin-bottom: var(--space-m);
  }
}

.employeeCard__name {
  font-family: 'Roboto Slab', serif;
  font-size: var(--font-size-l);
  font-weight: 300;
  line-height: var(--line-height-xl);

  @media (--tablet) {
    font-size: var(--space-xl);

    margin-bottom: var(--space-xs);
  }

  @media (--desktop-s) {
    margin-bottom: var(--space-3xs);
  }

  @media (--desktop-m) {
    font-size: var(--space-2xl);
    line-height: var(--line-height-3xl);
  }
}

.employeeCard__role {
  font-family: Roboto, sans-serif;
  font-size: var(--font-size-s);
  font-weight: 300;

  margin-bottom: var(--space-m);

  @media (--tablet) {
    font-size: var(--font-size-m);
  }
}

.employeeCard__goals {
  font-family: Roboto, serif;
  font-size: var(--font-size-s);
  font-weight: 300;

  margin-bottom: var(--space-xl);

  list-style: none;

  color: var(--color-gray);

  @media (--tablet) {
    font-size: var(--font-size-m);
  }

  @media (--desktop-s) {
    margin-bottom: var(--space-2xl);
  }
}

.employeeCard__goal {
  position: relative;

  padding-left: 14px;

  &::before {
    position: absolute;
    left: 0;

    content: '—';
  }
}

.employeeCard__goal:not(:last-child) {
  margin-bottom: var(--space-3xs);
}

.employeeCard__button {
  @media (--desktop-s) {
    width: 293px;
  }
}
