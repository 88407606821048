:root {
  /* spaces */
  --space-3xs: 4px;
  --space-2xs: 6px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 16px;
  --space-l: 20px;
  --space-xl: 24px;
  --space-2xl: 32px;
  --space-3xl: 48px;
  --space-4xl: 64px;
  --space-5xl: 96px;

  /* font-sizes */
  --font-size-2xs: 10px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 20px;
  --font-size-2xl: 22px;
  --font-size-3xl: 24px;
  --font-size-4xl: 32px;
  --font-size-5xl: 36px;
  --font-size-6xl: 48px;
  --font-size-7xl: 64px;

  /* line-heights */
  --line-height-2xs: 12px;
  --line-height-xs: 16px;
  --line-height-s: 18px;
  --line-height-m: 24px;
  --line-height-l: 31px;
  --line-height-xl: 32px;
  --line-height-2xl: 40px;
  --line-height-3xl: 48px;
  --line-height-4xl: 64px;
  --line-height-5xl: 80px;

  /* colors */
  --color-primary: #fff;
  --color-primary-hover: rgb(255 255 255 / 80%);
  --color-black: #000;
  --color-error: #f45b53;
  --color-gray: #ababab;
  --color-gray25: #404040;
  --color-sky-blue: #4d90ff;
  --color-sky-blue-hover: rgb(0 163 255 / 80%);
  --color-sky-blue-disabled: rgb(0 163 255 / 30%);
  --color-brandeis-blue: #0061ff;
  --color-brandeis-blue-hover: rgb(0 97 255 / 80%);
  --color-brandeis-blue-disabled: rgb(0 97 255 / 30%);
  --color-yellow: #ffe052;
  --color-black-olive: #404040;
  --bg-color-dark: #282828;
  --bg-color-night: #343434;
}
