.link {
  font: inherit;

  position: relative;

  padding-right: var(--space-m);

  text-decoration: underline;
  text-decoration: none;

  color: var(--color-primary);

  transition: 0.3s color;
}

.link::before {
  position: absolute;
  top: 0;
  right: 0;

  width: 14px;
  height: 14px;

  content: '';

  background-image: url('../assets/link-before.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.link--labelSize-small {
  padding-right: var(--space-s);
}

.link--labelSize-small::before {
  width: 11px;
  height: 11px;
}

.link__title {
  color: var(--color-sky-blue);

  transition: 0.3s color;
}

.link:hover {
  color: var(--color-primary-hover);
}

.link:hover .link__title {
  color: var(--color-sky-blue-hover);
}

.link__type_text {
  padding-right: 0;

  text-decoration: underline;
}

.link__type_text::before {
  display: none;
}
